:root {
  --primary: #1976d2;
  --hover: black;
  --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59,130,246,.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
}


.App {
  text-align: center;
}

.logo-height {
  min-height: 8vh;
  max-height: 8vh
}

.btn-primary {
  font-size: small;
  --bs-btn-color: #fff;
    --bs-btn-bg: #0d6efd;
    --bs-btn-border-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;
}

.App-logo {
  height: 60vmin;
  pointer-events: none;
}

.MuiAvatar-colorDefault {
  color: #fafafa;
  background-color: #1976d2 !important;;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: infinite 60s linear;
  }
}


/*@media (prefers-reduced-motion: no-preference) {
  .App-compass {
    /*animation: App-logo-spin infinite 20s linear;
  }
}*/

.scroll-to-top {
  background-color: white;
  right: 20px;
  bottom: 20px;
  position: fixed;
  z-index: 100;
  cursor: pointer;
  border-radius: 7px;
  width: 20px;
  height: 20px;
  box-shadow: 0 9px 25px 0 rgba(132, 128, 177, 0.28);
  border: none;
}

.scroll-to-top:active {
  transform: matrix(0.95, 0, 0, 0.95, 0, 0);
}

.App-compass {
  height: 24vmin;
  /*pointer-events: none;*/
  position: flex;
  opacity:0.4;
  transition: transform .2s;
}

.App-compass-mobile {
  height: 50vmin;
  /*pointer-events: none;*/
  position: flex;
  opacity:0.4;
  transition: transform .2s;
}

.App-compass-modal {
  height: 50vmin;
  /*pointer-events: none;*/
  position: flex;
  opacity:0.4;
  transition: transform .2s;
}

.vl {
  border-left: 6px solid green;
  height: 100px;
}

/* .App-compass:hover {
  transform:scale(1.5);
} */

.preview {
  height: 28vmin;
  pointer-events: none;
}

.mandatory {
  color: red;
}

.validationText {
  color: red;
  justify-content: center;
  text-align: center;
}

.infoText {
  color: cornflowerblue;
  justify-content: center;
  text-align: center;
}

.App-butt {
  border: 0px outset  var(--primary);
  background-color:  var(--primary);
  border-radius: 10px;
  height: 30px;
  cursor:pointer;
  margin: 0 5px;
  color:white;
  align-items: center;
}

.App-butt-cancel {
  border: 0px outset white;
  background-color: white;
  height: auto;
  height: 30px;
  border-radius: 10px;
  margin: 0 5px;
  color:black;
  align-items: center;
}

.App-butt-cancel:hover {
  background-color: lightgray;
  color:black;
}

.App-butt:hover {
  background-color: whitesmoke;
  color:var(--primary);
}

.App-butt:disabled {
  background-color: gray;
  color:darkgray;
}

.previousBtn {
  cursor: pointer;
  -moz-box-align: center;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  place-self: stretch;
  color: #242a31;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #e6ecf1;
  -webkit-transition: border .2s ease 0s;
  transition: border .2s ease 0s;
  box-shadow: 0 3px 8px 0 rgb(116 129 141 / 10%);
  text-decoration: none;
}

.nextBtn:hover, .previousBtn:hover {
  color: #744cbc;
  text-decoration: none;
  border: 1px solid #744cbc;
}

.leftArrow, .preRightWrapper {
  display: block;
  margin: 0;
  padding: 16px;
}

.nextPreviousTitle {
  display: block;
  margin: 0;
  padding: 0;
  -webkit-transition: color .2s ease 0s;
  transition: color .2s ease 0s;
}

.smallContent {
  display: block;
  margin: 0;
  padding: 0;
  color: #9daab6;
}

.preRightWrapper {
  flex: 1 1;
  text-align: right;
}

.leftArrow {
  color: #9daab6;
  flex: 0 0 auto;
  font-size: 24px;
  -webkit-transition: color .2s ease 0s;
  transition: color .2s ease 0s;
}

.smallContent span {
  font-size: 12px;
  line-height: 1.625;
  font-weight: 400;
}

.nextPreviousTitle span {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}

.nextPreviousWrapper {
    margin: 0;
    padding: 0;
    width: auto;
    display: grid;
    grid-template-rows: auto;
    -webkit-column-gap: 24px;
    -moz-column-gap: 24px;
    column-gap: 24px;
    grid-template-columns: calc(50% - 8px) calc(50% - 8px);
}


.big_icon {
  font-size: 150px;
  color: #1888ff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.butt {
  border: 1px outset #1888ff;
  background-color: #1888ff;
  height:200px;
  width:200px;
  cursor:pointer;
  font-size: 50px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin: 0 100px;
  border-radius: 10px;
  color:white;
}

.transparent-button {
  background-color: transparent;
  border: none;
}

.butt:hover {
  background-color: lightblue;
  color:black;
}

.text {
  margin: auto;
  color: white;
  font-size: 40px;
  font-weight: bold;
}

.sticky {
  position: sticky;
  top: 0;
  z-index:100;
}

.group {
  border-color: #43a92d;
  background-color: rgba(55, 139, 37, 0.05);
}



.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -2px rgba(0,0,0,.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}

.react-data-table-cell-wrap {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.truncate-tooltip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

/* Optional: Style for the native browser tooltip */
.truncate-tooltip[title]:hover:after {
  content: attr(title);
  /* Add more styling for the tooltip as needed */
}

.circle {
  display: flex;
  width: 150px;
  height: 150px;
  background-color: rgb(26, 184, 26);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.App-body{
  min-height: 85vh;
  /*background-color:#E5E5E5;*/
  background-color: rgb(250, 250, 252);
  display: flex;
  align-items: top;
  flex-direction: row;
  justify-content: center;
  flex-wrap:wrap;
  width: 100%;
}

.App-h1 {
  color: rgb(227, 94, 116);
  font-size: 42px;
  margin: 0 10px;
}

.image-div {
  background-size:"cover";
  background-repeat: no-repeat;
  transition: transform .2s; /* Animation */
}


.image-div:hover {
  transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.div {
  border: 1px solid saddlebrown;
}

.Scrollable-div {
  overflow-y: auto;
  vertical-align:top;
  display: inline-block;
  justify-content: center;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
  min-height: 80vh;
  min-width: 25vmin;
  align-items: center;
}

.Scrollable-div-mobile {
  overflow-y: auto;
  vertical-align:top;
  display: inline-block;
  justify-content: center;
  margin-top: 5px;
  margin-left: 2px;
  margin-bottom: 5px;
  min-height: 80vh;
  min-width: 25vmin;
  align-items: center;
}

.Scrollable-div-report {
  overflow-y: auto;
  vertical-align:top;
  display: inline-block;
  justify-content: center;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
  min-height: 80vh;
  min-width: 30vmin;
  align-items: center;
}

.Scrollable-div-Form {
  overflow-y: auto;
  vertical-align:top;
  display: inline-block;
  justify-content: center;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
  min-height: 80vh;
  min-width: 50vmin;
  align-items: center;
}

legend {
  background-color: var(--primary);
  color: #fff;
  padding: 3px 6px;
  font-weight: normal;
  font-size: small;
}

.infocontainer {
  max-width:
   40vmin;
}

.reportaction-font {
  font-size: large;
  font-weight: bold;
}


fieldset {
  border-radius: 10px;
  border: 1px gray;
  background-color: white;
  height: auto;
  --tw-shadow: 0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -2px rgba(0,0,0,.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}

.App-login {
  display: flex;
  flex-direction: column;
  color: black;
  align-items: center;
  margin: 0 20px;
}

.reloadButton {
  display: flex;
  flex-direction: column;
  color: black;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  margin-top: 20px;
}

.App-profile {
  flex-direction: row;
  display: flex;
  color: black;
  margin-left: 20px;
  column-gap: 20px;
  padding: 20px 20px 20px 20px;
  justify-content: center;
  position:relative
}

.helptext-div {
  max-width: 400px;
  border-radius: 10px;
  border: 1px solid gray;
  padding: 30px;
  justify-content: center;
}


.App-image {
  display: flex;
  max-width: 28vmax;
  overflow-y: auto;
  flex-direction: row;
  color: black;
  align-items: center;
  justify-content: top;
  flex-wrap: wrap;
}

.small-text {
  text-align: center;
  justify-content: center;
  color: black;
}

.small-text-black {
  text-align: center;
  justify-content: center;
  color: black;
}

.score {
  font-size: 14px;
  margin: 0 10px;
  color: black;
}


.custom-tooltip {
  justify-content: center;
  background-color: lightblue;
  font-size: 12px;
  margin: 0 10px;
  margin-top: auto;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

input[type=file] { width: 300px; border: 1px solid gray; font-size: small;}

.logout-text {
  color: rgba(18, 124, 206, 0.933);
}

.imageSelect {
  width: 175px;
  text-align: center;
  background: white;
  border: 1px solid #999;
  height: 25px;
  color: white;
  background-color:var(--primary);
  border-radius: 5px;
  box-shadow: 4px 4px #ccc;

}

.centeredAlertContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-table {
  border-collapse: separate;
  border-spacing: 1em;
  /*margin: 0 5px;*/
  flex-direction: row;
  width: 100%;
}

.App-table tfoot {
  justify-content: center;
}

select[name=showForm] {
  width: 150px;
  text-align: center;
  background: white;
  border: 1px solid #999;
  height: 25px;
  /*color: #1c87c9;*/
  background-color: #fff;
  border-radius: 5px;
  /*box-shadow: 4px 4px #ccc;*/
}

select[name=showFormMobile] {
  width: 95px;
  text-align: center;
  background: white;
  border: 1px solid #999;
  height: 25px;
  /*color: #1c87c9;*/
  background-color: #fff;
  border-radius: 5px;
  /*box-shadow: 4px 4px #ccc;*/
}

select {
  text-align: center;
  background: white;
  border: 1px solid #999;
  height: 25px;
  /*color: #1c87c9;*/
  background-color: #fff;
  border-radius: 5px;
  /*box-shadow: 4px 4px #ccc;*/
}

select#customerType {
  width: 150px;
  text-align: left;
  background: white;
  border: 1px solid #999;
  height: 25px;
  /*color: #1c87c9;*/
  background-color: #fff;
  border-radius: 5px;
  /*box-shadow: 4px 4px #ccc;*/
}

.ReactScrollToTop__Container {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

/* 
input[type=bus] {
  font-size: small;
  height: 25px;
  width: 150px;
  border: 1px solid #999;
  text-align: left;
  background: white;
  text-align: left;
  border-radius: 5px;
  margin-right: 10px;
  /*color: #1c87c9;*/
  /*box-shadow: 4px 4px #ccc;*/


input[type=text][name=showFormText] {
  font-size: small;
  height: 25px;
  width: 150px;
  border: 1px solid #999;
  text-align: left;
  background: white;
  text-align: left;
  border-radius: 5px;
  margin-right: 10px;
  /*color: #1c87c9;*/
  /*box-shadow: 4px 4px #ccc;*/
}

input[type=text][name=showFormTextMobile] {
  font-size: small;
  height: 25px;
  width: 95px;
  border: 1px solid #999;
  text-align: left;
  background: white;
  text-align: left;
  border-radius: 5px;
  /*color: #1c87c9;*/
  /*box-shadow: 4px 4px #ccc;*/
}

input[name=search_filter] {
  font-size: small;
  height: 25px;
  width: 300px;
  border: 1px solid #999;
  text-align: left;
  background: white;
  text-align: left;
  border-radius: 5px;
  /*color: #1c87c9;*/
  /*box-shadow: 4px 4px #ccc;*/
}

input[name=search_filter_mobile] {
  font-size: small;
  height: 25px;
  width: 250px;
  border: 1px solid #999;
  text-align: center;
  background: white;
  text-align: left;
  border-radius: 5px;
  text-align: center;
  /*color: #1c87c9;*/
  /*box-shadow: 4px 4px #ccc;*/
}

input[name=userName] {
  font-size: small;
  height: 25px;
  width: 150px;
  border: 1px solid #999;
  text-align: left;
  background: white;
  text-align: left;
  border-radius: 5px;
  /*color: #1c87c9;*/
  /*box-shadow: 4px 4px #ccc;*/
}
input[name=name] {
  font-size: small;
  height: 25px;
  width: 150px;
  border: 1px solid #999;
  text-align: left;
  background: white;
  text-align: left;
  border-radius: 5px;
  /*color: #1c87c9;*/
  /*box-shadow: 4px 4px #ccc;*/
}
input[name=emailAddress] {
  font-size: small;
  height: 25px;
  width: 150px;
  border: 1px solid #999;
  text-align: left;
  background: white;
  text-align: left;
  border-radius: 5px;
  /*color: #1c87c9;*/
  /*box-shadow: 4px 4px #ccc;*/
}

input[name=contactSubject] {
  font-size: small;
  height: 25px;
  width: 150px;
  border: 1px solid #999;
  text-align: left;
  background: white;
  text-align: left;
  border-radius: 5px;
  /*color: #1c87c9;*/
  /*box-shadow: 4px 4px #ccc;*/
}


input[type=password] {
  font-size: small;
  height: 25px;
  width: 150px;
  border: 1px solid #999;
  text-align: left;
  background: white;
  margin-right: 10px;
  text-align: left;
  border-radius: 5px;
  /*color: #1c87c9;*/
  /*box-shadow: 4px 4px #ccc;*/
}

.input-title {
  font-size: small;
  border: 1px solid lightgray;
  width: 250px;
  text-align: left;
  background: white;
  margin-right: 10px;
}

.input-title-mobile {
  font-size: medium;
  border: 1px solid lightgray;
  width: 100px;
  text-align: left;
  background: white;
  margin-right: 10px;
}

textarea {
  border: 1px solid lightgray;
}


select option {
  background: peachpuff;
}
.App-table-head {
  background-color: #1888ff;
  text-align: center;
  justify-content: center;
  margin: 10px;
  margin-top: 10;

}
.App-p {
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  font-size: large;
  margin: 0 100px;
}

.App-div {
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 20px;
  flex-wrap: wrap;
  max-height: 80vh;
}

.App-div-mobile {
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 5px;
  flex-wrap: wrap;
  max-height: 80vh;
}

.App-report{
  background-color: white;
  display: flex;
  align-items: top;
  flex-direction: row;
  justify-content: center;
  flex-wrap:wrap;
  position: relative;
}


.profileButton {
  background-color: white;
  border-radius: 10px;
  border: none;
  height: 30px;
}

.profileButton:hover {
  background-color: var(--hover);
  border-radius: 12px;
  border: none;
  color: white;
}

.App-header {
  background-color: peachpuff;
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-footer {
  margin-top: auto;
  min-height: 6vh;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color:  var(--hover);
  color: white;
}

.legal-container-document-dialog {
  width: 100%;
  text-align:justify
}

.legal-container-document {
  margin: 50px;
  width: 100%;
  text-align:justify
}

.legal-container-document h1 {
    font-size:40px
}
.legal-container-document h2 {
    font-size:30px
}
.legal-container-document h3 {
    font-size:23px
}
.legal-container-document h4 {
    font-size:20px
}

.legal-container-document h5 {
    font-size:15px
}

.patent-pending-section {
    font-size:15px
}

.offer-section {
  background: linear-gradient(to right, #1e88e5, #1976d2); /* Gradient from a lighter shade to #1976d2 */
  padding: 15px;
  text-align: center;
}

.offer-section-mobile {
  background: linear-gradient(to right, #1e88e5, #1976d2); /* Gradient from a lighter shade to #1976d2 */
  padding: 5px;
  text-align: center;
}

.offer-container {
  max-width: 80vw; /* Adjust the maximum width as per your desired size */
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.offer-text {
  margin: 0;
  color: #ffffff; /* Change to your desired text color */
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
  background: linear-gradient(to right, #1e88e5, #1976d2); /* Gradient from a lighter shade to #1976d2 */
  padding: 10px 20px; /* Adjust padding as needed */
  border-radius: 5px; /* Add rounded corners */
  /*white-space: nowrap;*/
}


.footer {
  background-color: var(--hover);
  padding: 8px;
  color: white;
  text-align: center;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
}

.footer-section {
  flex-basis: 100%;
  margin-bottom: 10px;
}

.footer p {
  margin: 0;
}

.patent-pending-section {
  text-align: center; /* Center the "PATENT PENDING" text */
}

.line-spacing {
  height: 10px; /* Adjust the height for desired line spacing */
}

.legal-links {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap; /* Allow links to wrap on smaller screens */
  justify-content: center;
  align-items: center; /* Center the links horizontally */
}

.legal-links li:not(:last-child) {
  margin-right: 10px;
  margin-bottom: 10px; /* Add spacing between links */
}

.legal-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.legal-links a:hover {
  color: white;
  text-decoration: underline;
}

.legal-links li:not(:last-child):not(:nth-last-child(2)) {
  margin-right: 10px;
}

.legal-links li:not(:last-child):not(:nth-last-child(2))::after {
  content: "|";
  margin-left: 10px;
}

.legal-links li:last-child::after,
.legal-links li:nth-last-child(2)::after {
  content: "";
  margin-left: 0;
}

.sticky-botton {
  position: sticky;
  top: 0;
}



.rdt_TableCol {
  font-weight: bold;
  font-size: small;
  color: white;
}


.rdt_TableHeadRow {
  background-color: #1888ff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


* {
  box-sizing: border-box;
}


.report-table {
  border-collapse: collapse;
  width: 100%;
}


.report-table th, .report-table td {
  text-align: left;
  padding: 8px;
  font-size: 15px;
}

.report-table tr:nth-child(even){background-color: #f2f2f2}


.report-table th {
  background-color: #97c1ec;
  color: white;
}

.report-table .selected, .report-table tbody td .selected {
  background-color: #6ccbfb;
  color: #fff;
}

.pdf-viewer-container {
  background-color: #525659;
  height: 90vh;
  overflow-x: auto;
}

.pdf-page {
  margin: 10px;
  padding: 5px;
  background-color: #525659;
}

.pdf-page-content {
  /* Add any specific styles for the PDF page content */
  background-color: #525659 !important;
}

.page-info {
  margin-top: 10px;
}

.loading-error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 10px;
}



/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  min-height: 80vh;
  min-width: 60vmax;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 1px solid lightgray;
}

.responsive-iframe-mobile {
  min-height: 80vh;
  min-width: 90vw;
  max-width: 95vw;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 1px solid lightgray;
}

.responsive-iframe-report {
  min-height: 80vh;
  min-width: 65vmax;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 1px solid lightgray;
}


.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}


.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}

#accessMessage {
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  width: 95%;
  margin-top: -9em;
  margin-left: -13em;
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.static-bar {
  position: flex;
  top: 60px; /* replace with the height of your nav bar */
  /* other styling */
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 500px;
  padding: 30px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #1976d2;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.blog-h1-style {
  text-align: left;
  font-size: 24px;
  margin-bottom: 20px;
  color: #1976d2;
}

.blog-h2-style {
  text-align: left;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
  color: #1976d2;
}

.blog-h3-style {
  text-align: left;
  font-size: 17px;
  margin-top: 30px;
  margin-bottom: 20px;
  color: #1976d2;
}

.blog-h3-style {
  text-align: left;
  font-size: 15px;
  margin-top: 30px;
  margin-bottom: 20px;
  color: #1976d2;
}


.blog-para-style {
  text-align: justify;
  font-size: 16px;
  margin-bottom: 15px;
  line-height: 1.5; /* Adjust line spacing as needed */
}

.blog-para-style-italics {
 font-style: italic;
 font-weight: bold;
 text-align: justify;
 font-size: 16px;
 margin-bottom: 15px;
 line-height: 1.5; /* Adjust line spacing as needed */
}


.blog-li-title-style {
  text-align: left;
  font-size: 17px;
  margin-top: 30px;
  margin-bottom: 20px;
  color: #1976d2;
}
.blog-ol-style {
  text-align: left;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 15px;
  color: #000; /* Text color for the entire list */
}

.blog-ol-style li {
  color: #000; /* Heading color for list item titles */
  list-style-type: disc; /* Change bullet style to filled circle */
}



@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#reportFrame {
    overflow: auto;
}

@media print {
  body * {
    display: none;
  }
  .printable, .printable * {
    display: block;
  }
}


