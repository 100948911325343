/* :root {
  --primary: #1976d2;
  --hover: black;
} */

:root {
  --primary-nav: white;
  --hover: #1976d2;
  --link-color: black;
  --menu-primary: white;
}

.navbar {
  /*revisit this color*/
  min-height: 8vh;
  max-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  background: var(--primary-nav);
  position: sticky;
  /*margin-bottom: auto;*/
}

.navbar-mobile {
  /*revisit this color*/
  min-height: 8vh;
  max-height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  background: var(--primary-nav);
  position: sticky;
  /*margin-bottom: auto;*/
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 5px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: right;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 8vh;
}

.nav-links {
  color: var(--link-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: var(--hover);
  border-radius: 10px;
  transition: all 0.2s ease-out;
  color: white;
}

.nav-links-plain {
  color: var(--link-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
}


.fa-bars {
  color: var(--primary);
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 8vw;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: var(--menu-primary);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    font-size: medium;
  }

  .nav-links {
    text-align: center;
    padding: 0.5rem;
    width: 100%;
    display: table;
  }

  .nav-links-plain {
    text-align: center;
    padding: 1rem;
    width: 100%;
    display: table;
  }


  .nav-links:hover {
    background-color: var(--hover);
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: var(--primary);
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: black;
    transition: 250ms;
  }

}
