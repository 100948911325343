.modalBackground {
    height: 75vh;
    background-color: rgba(200, 200, 200);
    border-radius: 12px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modalBackgroundUpload {
    height: 90vh;
    background-color: rgba(200, 200, 200);
    position: absolute;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modalBackground-link {
    /*width: 100vw;*/
    background-color: rgba(200, 200, 200);
    position: relative;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  .modalContainerUpload {
    /*width: 768px;*/
    height: 90vh;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    justify-content: top;
    z-index:100;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  .modalContainer {
    /*width: 768px;*/
    height: 75vh;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    justify-content: top;
    z-index:100;
  }

  .modalContainer-link {
    /*width: 768px;*/
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: relative;
    flex-direction: column;
    justify-content: center;
    padding: 25px;
    z-index:100;
  }
  
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }

  .titleCloseBtn-link {
    display: flex;
    justify-content: flex-end;
  }

  .imageButton {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px lightgray;
  }

  .imageButton:hover {
    background-color: var(--primary);
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }

  .titleCloseBtn-link button {
    background-color: transparent;
    border: none;
    font-size: 15px;
    cursor: pointer;
  }

  .div-plot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .modalContainer .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1 rem;
    text-align: center;
  }
  
  .modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .plot-image {
    height: 15vmin;
    /*pointer-events: none;*/
    position: flex;
    transition: transform .2s;
  }
  
  
  .modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    cursor: pointer;
  }

  .plotshape-text {
    font-size: 14px;
    margin: 0 10px;
    color: black;
    text-align: center;
    justify-content: center;
  }
  
  #cancelBtn {
    background-color: crimson;
  }

  @-webkit-keyframes progress-bar-stripes {
    0% {
      background-position-x: 1rem;
    }
  }
  
  @keyframes progress-bar-stripes {
    0% {
      background-position-x: 1rem;
    }
  }
  .progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
  }
  
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #0d6efd;
    transition: width 0.6s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none;
    }
  }
  
  .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
  }
  
  .progress-bar-animated {
    -webkit-animation: 1s linear infinite progress-bar-stripes;
    animation: 1s linear infinite progress-bar-stripes;
  }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
      animation: none;
    }
  }

  .alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  
  .alert-heading {
    color: inherit;
  }
  
  .alert-link {
    font-weight: 700;
  }
  
  .alert-dismissible {
    padding-right: 3rem;
  }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem;
  }
  
  .alert-primary {
    color: #084298;
    background-color: #cfe2ff;
    border-color: #b6d4fe;
  }
  .alert-primary .alert-link {
    color: #06357a;
  }
  
  .alert-secondary {
    color: #41464b;
    background-color: #e2e3e5;
    border-color: #d3d6d8;
  }
  .alert-secondary .alert-link {
    color: #34383c;
  }
  
  .alert-success {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
  }
  .alert-success .alert-link {
    color: #0c4128;
  }
  
  .alert-info {
    color: #055160;
    background-color: #cff4fc;
    border-color: #b6effb;
  }
  .alert-info .alert-link {
    color: #04414d;
  }
  
  .alert-warning {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
  }
  .alert-warning .alert-link {
    color: #523e02;
  }
  
  .alert-danger {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
  }
  .alert-danger .alert-link {
    color: #6a1a21;
  }
  
  .alert-light {
    color: #636464;
    background-color: #fefefe;
    border-color: #fdfdfe;
  }
  .alert-light .alert-link {
    color: #4f5050;
  }
  
  .alert-dark {
    color: #141619;
    background-color: #d3d3d4;
    border-color: #bcbebf;
  }
  .alert-dark .alert-link {
    color: #101214;
  }
  